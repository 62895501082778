import React from 'react';

const FlexPosPage = React.lazy(() => import('./FlexPosPage'));
const LoppeCreateUser = React.lazy(() => import('./FlexposLoppe/LoppeCreateUser'));
const LoppeUserPage = React.lazy(() => import('./FlexposLoppe/LoppeUserPage'));
const LoppeEditUser = React.lazy(() => import('./FlexposLoppe/LoppeEditUser'));
const LoppeCreateItem = React.lazy(() => import('./FlexposLoppe/loppeCreateItem/LoppeCreateItem'));
const LoppePayout = React.lazy(() => import('./FlexposLoppe/loppePayout/LoppePayout'));
const LoppeCreateBooth = React.lazy(() => import('./FlexposLoppe/LoppeCreateBooth/LoppeCreateBooth'));
const LoppeManageBooth = React.lazy(() => import('./FlexposLoppe/LoppeManageBooth'));
const LoppeDetailedSales = React.lazy(() => import('./FlexposLoppe/detailedSale/LoppeDetailedSales'));
const LoppeSearchItem = React.lazy(() => import('./FlexposLoppe/LoppeSearchItem'));
const LoppeConvertBooth = React.lazy(() => import('./FlexposLoppe/LoppeConvertBooth'));
const LoppeBoothOverview = React.lazy(() => import("./FlexposLoppe/LoppeBoothOverview"));

const FlexposConfig = {
	routes: [
		{
			path: '/',
			element: <FlexPosPage />,
		},
		{
			path: '/createuser',
			element: <LoppeCreateUser />,
		},
		{
			path: '/userpage',
			element: <LoppeUserPage />,
		},
		{
			path: '/edituser',
			element: <LoppeEditUser />,
		},
		{
			path: '/createitems',
			element: <LoppeCreateItem />,
		},
		{
			path: '/payout',
			element: <LoppePayout />,
		},
		{
			path: '/createbooth',
			element: <LoppeCreateBooth />,
		},
		{
			path: '/convertbooth',
			element: <LoppeConvertBooth />,
		},
		{
			path: '/extendbooth',
			element: <LoppeManageBooth />,
		},
		{
			path: '/detailedsales',
			element: <LoppeDetailedSales />,
		},
		{
			path: '/searchitem',
			element: <LoppeSearchItem />,
		},
		{
			path: '/boothoverview',
			element: <LoppeBoothOverview/>
		}
	],
};

export default FlexposConfig;
