import { combineReducers } from '@reduxjs/toolkit';
import frontend from './frontendSlice';
import loppeData from '../../main/apps/FlexposLoppe/store/loppeDataSlice';
import printLabel from '../../main/apps/FlexposLoppe/store/printLabelSlice';

const sharedReducers = combineReducers({
	frontend,
	loppeData,
	printLabel,
});

export default sharedReducers;
