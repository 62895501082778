import FuseUtils from '@ameroservices-platform/shared/fuse/utils';
import appsConfigs from '@ameroservices-platform/loppe-flexposui/app/main/apps/appsConfigs';
import FuseLoading from '@ameroservices-platform/loppe-flexposui/fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import authRoles from '@ameroservices-platform/loppe-flexposui/app/auth/authRoles';

const routeConfigs = [...appsConfigs];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: 'loading',
		element: <FuseLoading />,
	},
	{
		path: '*',
		element: <Navigate to="apps/error/404" />,
	},
];

export default routes;
