import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { selectCurrLangDir } from '@ameroservices-platform/loppe-flexposui/app/store/i18nSlice';
import withAppProviders from './withAppProviders';
import { Auth } from './auth';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { LocalizationProvider } from '@mui/lab';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment-timezone';
import 'moment/locale/da';
import BrowserRouter from '@ameroservices-platform/loppe-flexposui/fuse/core/BrowserRouter';
import FuseAuthorization from '@ameroservices-platform/loppe-flexposui/fuse/core/FuseAuthorization';
import FuseTheme from '@ameroservices-platform/loppe-flexposui/fuse/core/FuseTheme';
import FuseLayout from '@ameroservices-platform/loppe-flexposui/fuse/core/FuseLayout';
import FlexPOSErrorBoundary from '@ameroservices-platform/loppe-flexposui/app/ui-components/FlexPOSErrorBoundary';
import LaunchDarkly from '@ameroservices-platform/shared/ui-components/LaunchDarkly';
import organisationType from '@ameroservices-platform/shared/enums/organisationType';

// import axios from 'axios';
/**
 * Axios HTTP Request defaults
 */
// axios.defaults.baseURL = "";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

const emotionCacheOptions = {
	rtl: {
		key: 'muirtl',
		stylisPlugins: [],
		insertionPoint: document.getElementById('emotion-insertion-point')
	},
	ltr: {
		key: 'muiltr',
		stylisPlugins: [],
		insertionPoint: document.getElementById('emotion-insertion-point')
	}
};

moment.tz.setDefault('Europe/Copenhagen');
moment.locale('da');

const env = process.env.NODE_ENV;
if (env === 'production') {
	window.addEventListener('contextmenu', e => e.preventDefault());
}

const App = () => {
	const langDirection = useSelector(selectCurrLangDir);

	return (
		<CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<FlexPOSErrorBoundary>
					<Auth>
						<LaunchDarkly type={organisationType.LOPPE}>
							<BrowserRouter>
								<FuseAuthorization>
									<FuseTheme>
										<SnackbarProvider
											maxSnack={5}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right'
											}}
											classes={{
												containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99'
											}}
										>
											<FuseLayout />
										</SnackbarProvider>
									</FuseTheme>
								</FuseAuthorization>
							</BrowserRouter>
						</LaunchDarkly>
					</Auth>
				</FlexPOSErrorBoundary>
			</LocalizationProvider>
		</CacheProvider>
	);
};

export default withAppProviders(App)();
