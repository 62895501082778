import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import countries from '../../countries';

const frontendSlice = createSlice({
	name: 'frontend',
	initialState: {
		settings: null,
		theme: {},
		navigations: {},
		logos: {
			header: '',
			footer: '',
			loading: '',
			headerCartMobile: '',
			payments: 'visa-mastercard-pensopay.png',
		},
		informations: [],
		socialMedia: [],
		about: { heading: '', text: '' },
		openingHours: { heading: '', text: '' },
		terms: [],
		lists: {},
		cookieScriptId: null,
		defaultPageUrl: null,
		flexposSettings: {
			categories: [],
			initData: {
				products: [],
				eventGroups: {},
				locations: [],
			},
		},
		modules: {},
		footer: {},
		header: {},
		mobileNavigationMenu: {},
		checkoutHeader: {},
		checkoutHeaderUrls: [],
		countries: {},
	},
	reducers: {
		setSettings(state, action) {
			state.settings = action.payload;
			state.theme = action.payload.theme;
			state.logos = action.payload.logos;
			state.navigations = action.payload.navigations;
			state.informations = action.payload.informations;
			state.socialMedia = action.payload.socialMedia;
			state.about = action.payload.about;
			state.openingHours = action.payload.openingHours;
			state.terms = action.payload.terms;
			state.lists = action.payload.lists;
			state.modules = action.payload.modules;
			state.cookieScriptId = action.payload.cookieScriptId;
			state.defaultPageUrl = action.payload.defaultPageUrl;
			state.footer = action.payload.footer;
			state.header = action.payload.header;
			state.mobileNavigationMenu = action.payload.mobileNavigationMenu;
			state.checkoutHeader = action.payload.checkoutHeader;
			state.checkoutHeaderUrls = action.payload.checkoutHeaderUrls;
			const language = 'DA_dk';
			const c = action.payload.countries
				? action.payload.countries
						.filter((code) => countries[code])
						.reduce((accCountries, curCountry) => {
							accCountries[curCountry] = countries[curCountry][language];
							return accCountries;
						}, {})
				: { DK: countries.DK[language], FO: countries.FO[language], GL: countries.GL[language] };
			const orderedCountries = {};
			Object.keys(c)
				.sort()
				.forEach((key) => {
					orderedCountries[key] = c[key];
				});
			state.countries = orderedCountries;

			const { flexposSettings } = action.payload;
			if (flexposSettings && flexposSettings.categories) {
				flexposSettings.categories = Object.values(flexposSettings.categories).sort(
					(a, b) => a.order - b.order
				);
			}
			if (flexposSettings && flexposSettings.products) {
				flexposSettings.products = Object.values(flexposSettings.products).sort((a, b) => a.order - b.order);
			}
			if (!flexposSettings || !flexposSettings.requiredCustomerFields) {
				flexposSettings.requiredCustomerFields = [
					'firstname',
					'lastname',
					'email',
					'phone',
					'address',
					'postalCode',
					'city',
				];
			}
			if (!flexposSettings || !flexposSettings.createCustomerBasedOn) {
				flexposSettings.createCustomerBasedOn = 'email';
			}
			state.flexposSettings = flexposSettings;
		},
	},
});

export function getList(lists, location) {
	const locs = location.split('.');
	if (locs.length > 0 && locs[0] === 'lists') {
		locs.splice(0, 1);
	}
	return _.get(lists, locs);
}

export function mergeThemes(defaultTheme, dynamicTheme) {
	if (dynamicTheme.typography && dynamicTheme.typography.fontFamily) {
		Object.keys(defaultTheme.typography).forEach((key) => {
			if (defaultTheme.typography[key] instanceof Object && !(defaultTheme.typography[key] instanceof Array)) {
				defaultTheme.typography[key].fontFamily = dynamicTheme.typography.fontFamily;
			}
		});
	}
	return _.merge(defaultTheme, dynamicTheme);
}

export const { setSettings } = frontendSlice.actions;

export default frontendSlice.reducer;

export const selectTheme = (state) => state.shared.frontend.theme;
export const selectSettings = (state) => state.shared.frontend.settings;
export const selectLogos = (state) => state.shared.frontend.logos;
export const selectNavigation = (state) => state.shared.frontend.navigations;
export const selectInformations = (state) => state.shared.frontend.informations;
export const selectSocialMedia = (state) => state.shared.frontend.socialMedia;
export const selectAbout = (state) => state.shared.frontend.about;
export const selectOpeningHours = (state) => state.shared.frontend.openingHours;
export const selectTerms = (state) => state.shared.frontend.terms;
export const selectLists = (state) => state.shared.frontend.lists;
export const selectCookieScriptId = (state) => state.shared.frontend.cookieScriptId;
export const selectDefaultPageUrl = (state) => state.shared.frontend.defaultPageUrl;
export const selectFlexposSettings = (state) => state.shared.frontend.flexposSettings;
export const selectModules = (state) => state.shared.frontend.modules;
export const selectFooter = (state) => state.shared.frontend.footer;
export const selectHeader = (state) => state.shared.frontend.header;
export const selectMobileNavigationMenu = (state) => state.shared.frontend.mobileNavigationMenu;
export const selectCheckoutHeader = (state) => state.shared.frontend.checkoutHeader;
export const selectCheckoutHeaderUrls = (state) => state.shared.frontend.checkoutHeaderUrls;
export const selectCountries = (state) => state.shared.frontend.countries;
