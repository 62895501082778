import { createSlice } from '@reduxjs/toolkit';
import {
	getPdfDownloadLink,
	markBarcodesAsPrinted,
	markBarcodesAsPrintedOptimizedV2
} from '@ameroservices-platform/loppe-flexposui/app/main/apps/FlexposLoppe/store/loppeDataSlice';
import flexposApiService from '@ameroservices-platform/shared/services/flexsdk';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

export const PrintState = Object.freeze({
	IDLE: 'idle',
	LOADING: 'loading',
	SUCCESS: 'success',
	ERROR: 'error'
});

const printLabelSlice = createSlice({
	name: 'printLabel',
	initialState: {
		mainState: PrintState.IDLE,
		generatingLabels: PrintState.IDLE,
		sendingToPrint: PrintState.IDLE,
		markingAsPrinted: PrintState.IDLE,
		errorMessage: null,
		productGroup: null
	},
	reducers: {
		setProductGroup: (state, action) => {
			state.productGroup = action.payload;
		},
		setMainState: (state, action) => {
			state.mainState = action.payload;
		},
		setGeneratingLabels: (state, action) => {
			state.generatingLabels = action.payload;
		},
		setSendingToPrint: (state, action) => {
			state.sendingToPrint = action.payload;
		},
		setMarkingAsPrinted: (state, action) => {
			state.markingAsPrinted = action.payload;
		},
		setErrorMessage: (state, action) => {
			state.errorMessage = action.payload;
		},
		resetPrintState: state => {
			state.mainState = PrintState.IDLE;
			state.generatingLabels = PrintState.IDLE;
			state.sendingToPrint = PrintState.IDLE;
			state.markingAsPrinted = PrintState.IDLE;
			state.errorMessage = null;
		}
	}
});

export const {
	setMarkingAsPrinted,
	setSendingToPrint,
	setGeneratingLabels,
	setMainState,
	setProductGroup,
	setErrorMessage,
	resetPrintState
} = printLabelSlice.actions;

export default printLabelSlice.reducer;

export const productGroupListener = productGroupUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('ticketTypes')
		.doc(productGroupUid)
		.onSnapshot(doc => {
			dispatch(setProductGroup({ ...doc.data(), uid: doc.id }));
		});
};

export const markLabelsAsPrinted = productWithBarcode => async dispatch => {
	dispatch(setMarkingAsPrinted(PrintState.LOADING));
	try {
		await Promise.all(
			productWithBarcode.map(product => {
				return markBarcodesAsPrinted(
					product.uid,
					product.labels.map(label => label.barcode)
				);
			})
		);
		dispatch(setMarkingAsPrinted(PrintState.SUCCESS));
	} catch (e) {
		dispatch(setMarkingAsPrinted(PrintState.ERROR));
		throw new Error('Der skete en fejl ved markeringen af labels');
	}
};

export const markLabelsAsPrintedOptimized = labels => async dispatch => {
	dispatch(setMarkingAsPrinted(PrintState.LOADING));
	try {
		const groupedByProductUid = labels.reduce((acc, cur) => {
			if (!acc[cur.productUid]) {
				acc[cur.productUid] = [];
			}
			acc[cur.productUid].push(cur.barcode);
			return acc;
		}, {});
		await Promise.all(
			Object.entries(groupedByProductUid).map(([productUid, labels]) => {
				return markBarcodesAsPrinted(productUid, labels);
			})
		);
		dispatch(setMarkingAsPrinted(PrintState.SUCCESS));
	} catch (e) {
		dispatch(setMarkingAsPrinted(PrintState.ERROR));
		throw new Error('Der skete en fejl ved markeringen af labels');
	}
};

export const markLabelsAsPrintedOptimizedV2 = labels => async dispatch => {
	dispatch(setMarkingAsPrinted(PrintState.LOADING));
	try {
		const groupedByProductUid = labels.reduce((acc, cur) => {
			if (!acc[cur.productUid]) {
				acc[cur.productUid] = [];
			}
			acc[cur.productUid].push(cur.barcode);
			return acc;
		}, {});
		await markBarcodesAsPrintedOptimizedV2(groupedByProductUid);
		dispatch(setMarkingAsPrinted(PrintState.SUCCESS));
	} catch (e) {
		dispatch(setMarkingAsPrinted(PrintState.ERROR));
		throw new Error('Der skete en fejl ved markeringen af labels');
	}
};

export const generateLabelsForPrint = productWithBarcode => async dispatch => {
	dispatch(setGeneratingLabels(PrintState.LOADING));
	let labelsPdfUrls = [];
	try {
		labelsPdfUrls = await Promise.all(
			productWithBarcode.reduce((acc, cur) => {
				cur.labels.forEach(label => {
					acc.push(getPdfDownloadLink(label.pdfUrl));
				});
				return acc;
			}, [])
		);
		dispatch(setGeneratingLabels(PrintState.SUCCESS));
	} catch (e) {
		dispatch(setGeneratingLabels(PrintState.ERROR));
		throw new Error('Der skete en fejl i genereringen af labels');
	}
	return labelsPdfUrls;
};

export const sendLabelsToPrint = labels => async dispatch => {
	dispatch(setSendingToPrint(PrintState.LOADING));
	if (labels.length > 0) {
		try {
			await flexposApiService.downloadAndPrintMultiplePDF(labels);
			dispatch(setSendingToPrint(PrintState.SUCCESS));
		} catch (e) {
			dispatch(setSendingToPrint(PrintState.ERROR));
			throw new Error('Der skete en fejl ved print af labels');
		}
		return true;
	} else {
		dispatch(setSendingToPrint(PrintState.ERROR));
		throw new Error('Ingen labels blev sent til print');
	}
};

export const sendLabelsToPrintOptimized = labels => async dispatch => {
	dispatch(setSendingToPrint(PrintState.LOADING));
	if (labels.length > 0) {
		try {
			await flexposApiService
				.startPrint(
					'labelprinter',
					'C:\\FlexPOS\\loppelabel.lab',
					1,
					labels.map(label => {
						const allowedTags = ['name', 'sublocation', 'productNumber'];
						if (label.prices) {
							label.prices.forEach(price => {
								if (price.price > 0) {
									label[`prices_${price.type.toLowerCase()}`] = (price.price / 100).toString();
									allowedTags.push(`prices_${price.type.toLowerCase()}`);
								}
							});
						}
						if (label && label.displayName) {
							label.name = label.displayName;
						}
						if (!label[`prices_sale`]) {
							allowedTags.push(`prices_sale`);
							label[`prices_sale`] = '';
						}
						return {
							Parameters: [
								// { Tag: 'Barcode', Value: Number.parseInt(label.barcode, 10) },
								{ Tag: 'Barcode', Value: label.barcode },
								{ Tag: 'id', Value: label.id + '-' + label.barcode },
								...allowedTags.map(key => {
									return { Tag: key, Value: label[key] || '' };
								})
							]
						};
					})
				)
				.catch(console.error);
			dispatch(setSendingToPrint(PrintState.SUCCESS));
		} catch (e) {
			console.error('[PRINT ERROR] ' + e?.message || e);
			dispatch(setSendingToPrint(PrintState.ERROR));
			throw new Error('Der skete en fejl ved print af labels');
		}
		return true;
	} else {
		dispatch(setSendingToPrint(PrintState.ERROR));
		throw new Error('Ingen labels blev sent til print');
	}
};

export const archiveProduct = async (productUid, archivedBool) => {
	const response = await firebaseService.callFunctionByName(
		'organisationProductArchiveProduct',
		{
			productUid: productUid,
			archived: archivedBool
		},
		true
	);
	return response?.data;
};

export const selectMainState = state => state.shared.printLabel.mainState;
export const selectGeneratingLabels = state => state.shared.printLabel.generatingLabels;
export const selectSendingToPrint = state => state.shared.printLabel.sendingToPrint;
export const selectMarkingAsPrinted = state => state.shared.printLabel.markingAsPrinted;
export const selectErrorMessage = state => state.shared.printLabel.errorMessage;
export const selectProductGroup = state => state.shared.printLabel.productGroup;
