import FuseSuspense from '@ameroservices-platform/loppe-flexposui/fuse/core/FuseSuspense';
import AppContext from '@ameroservices-platform/loppe-flexposui/app/AppContext';
import React, { useContext } from 'react';
import { useRoutes } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import FuseMessage from '@ameroservices-platform/loppe-flexposui/fuse/core/FuseMessage';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#ffffff !important',
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		'&.boxed': {
			maxWidth: 1120,
			margin: '0 auto',
			boxShadow: theme.shadows[3],
		},
		'&.container': {
			'& .container': {
				maxWidth: 1120,
				width: '100%',
				margin: '0 auto',
			},
			'& .navigation': {},
		},
	},
	content: {
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 4,
	},
	toolbarWrapper: {
		display: 'flex',
		position: 'relative',
		zIndex: 5,
	},
	toolbar: {
		display: 'flex',
		flex: '1 0 auto',
	},
	footerWrapper: {
		position: 'relative',
		zIndex: 5,
	},
	footer: {
		display: 'flex',
		flex: '1 0 auto',
	},
}));

function Layout4(props) {
	const appContext = useContext(AppContext);
	const { routes } = appContext;
	const classes = useStyles(props);
	const isProduction = process.env.NODE_ENV === 'production';

	return (
		<div id="fuse-layout" className={clsx(classes.root, isProduction && 'remove-mouse')}>
			<div className="flex flex-1 flex-col overflow-hidden relative">
				<div className="flex flex-auto flex-col relative h-full">
					<FuseSuspense>{useRoutes(routes)}</FuseSuspense>
				</div>
			</div>
			<FuseMessage />
		</div>
	);
}

export default React.memo(Layout4);
