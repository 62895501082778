import React from 'react';
import Typography from '@mui/material/Typography';
import FuseAnimateAdapter from '@ameroservices-platform/shared/ui-components/FuseAnimateAdapter';
import Button from '@mui/material/Button';
import { ButtonGroup } from '@mui/material';
import moment from 'moment-timezone';
import flexposApiService from '@ameroservices-platform/shared/services/flexsdk';

export default class FlexPOSErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true });
		if (error.message) {
			console.log('[ERROR] ' + error.message);
		}
		// You can also log the error to an error reporting service
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className="flex flex-col flex-1 items-center justify-center p-16">
					<style>{`body { background-color: #ffffff !important; }`}</style>
					<ButtonGroup orientation="vertical" className="fixed top-2 right-2">
						<Button
							className="rounded-none"
							onClick={() => {
								window.localStorage.clear();
								window.location.reload();
							}}
							variant="outlined"
							color="primary"
						>
							Clear Cache
						</Button>
						<Button
							className="rounded-none"
							onClick={() => {
								this.props.logoutUser();
							}}
							variant="outlined"
							color="primary"
						>
							Log ud
						</Button>
						<Button
							className="rounded-none"
							onClick={async () => {
								await flexposApiService.closeBrowser();
							}}
							variant="outlined"
							color="primary"
						>
							Luk
						</Button>
					</ButtonGroup>
					<div className="max-w-512 text-center">
						<FuseAnimateAdapter animation="transition.expandIn" delay={100}>
							<Typography variant="h1" color="inherit" className="font-medium mb-16">
								Fejl 500
							</Typography>
						</FuseAnimateAdapter>

						<FuseAnimateAdapter delay={500}>
							<Typography variant="h3" color="inherit" className="mb-16 text-black">
								Der skete en fejl
							</Typography>
						</FuseAnimateAdapter>

						<FuseAnimateAdapter delay={600}>
							<Typography variant="h6" color="inherit" className="text-black">
								Forsøg venligst handlingen igen eller
							</Typography>
						</FuseAnimateAdapter>
						<FuseAnimateAdapter delay={600}>
							<Typography variant="h6" color="inherit" className="mb-48 text-black">
								kontakt Amero Support
							</Typography>
						</FuseAnimateAdapter>

						<FuseAnimateAdapter delay={800}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => {
									window.location.replace(`/${window.location.search}`);
								}}
								className="rounded-none"
							>
								Genindlæs siden
							</Button>
						</FuseAnimateAdapter>
						<FuseAnimateAdapter delay={1000}>
							<Typography color="textSecondary" className="mt-48">
								<strong>Tidspunkt for fejl:</strong> {moment().format('DD-MM-YYYY HH:mm:ss')}
							</Typography>
						</FuseAnimateAdapter>
					</div>
				</div>
			);
		}
		return this.props.children;
	}
}
